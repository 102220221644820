import './App.scss';

import PropTypes from 'prop-types'
import React from "react"
import Home from './Home';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = props => {
  return (
    <React.Fragment>
      <Home/>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

export default App;