import React, { useEffect, useState } from "react"
import logo from './assets/images/logo.svg';
import tick from './assets/images/tick.svg';
import err from './assets/images/err.svg';
import time from './assets/images/time.svg';
import ok from './assets/images/ok.svg';
import down from './assets/images/down.svg';

import axios from "axios";
import moment from 'moment';
import PuffLoader from "react-spinners/PuffLoader";

const md2json = require('md-2-json');

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const Home = () => {
    const [servicesArr, setServicesArr] = useState([]);
    const [responseTime, setResponseTime] = useState(0);
    const [apiError, setApiError] = useState(false);
    const [loading, setLoading] = useState(true);

    const [servicesArrValues, setServicesArrValues] = useState([]);
    const [avgTimeArr, setAvgTimeArr] = useState([]);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


    useEffect(() => {
        getMetricsAvgSpeed();
        getMetricsWithQuery()

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


     // const step = 1 * 60 * 60 ; // 1 point every hour
    let step = windowDimensions?.width >= 1099 ? 0.3 * 1 * 1440 : windowDimensions?.width > 360 ? 0.8 * 1 * 1440 : 1 * 1 * 1440; // 1 point every 7 mins for one day
    let now = moment().unix();
    let yesterday = moment().subtract(1, "day");
    let yesterdayU = yesterday.unix()

    const getMetricsWithQuery = () => {
        setLoading(true)
        const q = 'mcore_health_status';
        axios.post('https://developer-api.seemelissa.com/v1/metrics/query', { name: q, end: now, start: yesterdayU, step })
            .then(res => {
                let services = [];
                let faulty = [];
                if (res.data?.status == 'success') {
                    const series = res.data.data.result;
                    series.forEach((serie) => {
                        if (serie.values[serie.values.length - 1][1] == 0) faulty.push(serie.values[serie.values.length - 1])
                        services.push({
                            name: serie.metric.service, status: serie.values.map(v => {
                                return { time: moment.unix(v[0]).format("DD.MM HH:mm"), value: Number(v[1]) }
                            })
                        })
                    });
                    setServicesArrValues(faulty)
                    setServicesArr(services)
                    setLoading(false)
                }
            })
            .catch(err => { setApiError(true) })
    }

    const requestOne = axios.post('https://developer-api.seemelissa.com/v1/metrics/query', { name: 'mcore_request_execution_time_seconds_sum', end: now, start: yesterdayU, step });
    const requestTwo = axios.post('https://developer-api.seemelissa.com/v1/metrics/query', { name: 'mcore_request_execution_time_seconds_count', end: now, start: yesterdayU, step });

    const getMetricsAvgSpeed = () => {
        setLoading(true)
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
            if (responses[0].data.status == 'success' && responses[1].data.status == 'success') {
                const sumsR = responses[0].data.data.result
                const countsR = responses[1].data.data.result

                let sumsArr = [];
                let countsArr = [];

                sumsR.forEach(s => sumsArr.push(...s.values.map(v => { return v[1] })))
                countsR.forEach(s => countsArr.push(...s.values.map(v => { return v[1] })))
                let sum = sumsArr.reduce((a, b) => Number(a) + Number(b), 0)
                let count = countsArr.reduce((a, b) => Number(a) + Number(b), 0)
                setResponseTime(sum / count)
                setAvgTimeArr(sumsR[sumsR.length - 1].values.map(s => { return { time: moment.unix(s[0]).format("DD.MM HH:mm") } }))
            } else {
                setResponseTime(0)
            }

            setLoading(false)
        })).catch(errors => {
            console.log(errors)
            setApiError(true)
        })
    }


    const getServiceName = (name) => {
        switch (name) {
            case 'LoRaWAN_API':
                return "LoRaWAN® API";
            case 'LoRaWAN_Broker':
                return 'LoRaWAN® Device Server';
            case 'bobbie_broker':
                return 'WiFi & LoRa Device Server';
            case 'enterprise':
                return "MClimate Enterprise Solution";
            case 'mongoDB':
                return 'Device Logs Service';
            case 'mySQL':
                return 'MClimate API';
            case 'redis':
                return 'WiFi & LoRa Device Commands Service';
            default:
                return name
        }
    }

    let showServices = ['LoRaWAN_API', 'LoRaWAN_Broker', 'bobbie_broker', 'enterprise', 'mongoDB', 'redis', 'mySQL'];

    return (
        <React.Fragment>
            <div className="page-content">
                <div style={{ display: loading ? 'flex' : 'none' }} className="overlay">
                    <PuffLoader
                        color={'#00aeef'}
                        loading={true}
                        cssOverride={{
                            margin: "0 auto",
                            borderColor: "#00aeef",
                        }}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>

                <div className="header">
                    <img src={logo} />
                    <a className="btn main contact" target='_blank' href="https://mclimate.eu/pages/contacts">Contact</a>
                </div>


                <div className="head">
                    <h2>Services status</h2>
                    <img src={servicesArrValues.length > 0 ? down : ok} />
                    <p className={`${servicesArrValues.length > 0 ? 'down' : ''}`}>
                        {servicesArrValues.length > 0 ? 'Some services are having partial outage' : 'All systems are operational'}
                    </p>
                </div>

                <div className="services">
                    {servicesArr?.filter(s => showServices.indexOf(s.name) !== -1).map((s, i) => {
                        if (s.name !== "") {
                            return <div key={i} className={`service ${s?.status[s?.status.length - 1].value == 0 || (s.name === 'mySQL' && apiError) ? 'down' : ''}`}>
                                <div className="status-overview">
                                    <span className="name">{getServiceName((s.name))}</span>
                                    <span className="status hide-mobile">
                                        <span>
                                            <img width={15} src={s?.status[s?.status.length - 1].value == 0 || (s.name === 'mySQL' && apiError) ? err : tick} />
                                            {s?.status[s?.status.length - 1].value == 0 ? 'Not operational' : 'Operational'}
                                        </span>
                                    </span>
                                </div>

                                <div className="status-values">
                                    <span className="dots">
                                        {s?.status?.map((s, i) => {
                                            return <span key={i} className="status-holder">
                                                <span className="hour-status" style={{ backgroundColor: s.value == 1 ? '#00C770' : '#E70A4E' }}>
                                                    <span className="status-label">{s.time}</span>
                                                </span>
                                            </span>
                                        })}
                                    </span>
                                    <span className="last-update hide-mobile">Last update: {s?.status[s?.status?.length - 1].time}, (24hr history)</span>
                                </div>
                                <div className="status-overview">
                                    <span className="status hide-desktop">
                                        <span>
                                            <img width={15} src={s?.status[s?.status.length - 1].value == 0 ? err : tick} /> {s?.status[s?.status.length - 1].value == 0 ? 'Not operational' : 'Operational'}
                                        </span>
                                        <span className="last-update hide-desktop">Last update: {s?.status[s?.status?.length - 1].time}, (24hr history)</span>
                                    </span>
                                </div>
                            </div>
                        }
                    })}

                    <div className="service">
                        <div className="status-overview">
                            <span className="name">Avg request time</span>
                            <span className="status time">
                                <img width={15} src={time} /> {responseTime.toFixed(2)} sec
                            </span>
                        </div>
                        <div className="status-values" style={{ height: '70px' }}>
                            <div className="dots"></div>
                            <span className="last-update hide-mobile"> {avgTimeArr.length > 0 ? <>Last update: {avgTimeArr[avgTimeArr.length - 1].time}, (24hr history)</> : ''}</span>
                        </div>

                        <div className="status-overview">
                            <span className="status hide-desktop">
                                <span className="last-update hide-desktop">{avgTimeArr.length > 0 ? <>Last update: {avgTimeArr[avgTimeArr.length - 1].time}, (24hr history)</> : ''}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="main-footer">
                <div className="footer-row footer-columns clearfix footer-shrink">
                    <div className="bottom-footer-row-wrapper">
                        <div className="footer-row footer-columns clearfix footer-row-bottom">

                            <div className="footer-column footer-menu logo-menu" style={{ marginRight: "10%" }}>
                                <img src="//cdn.shopify.com/s/files/1/0043/8874/9379/t/5/assets/mclimate-logo-footer.svg?v=32954462808082457811660217874/" />
                                <p className="footer-copyright">
                                    © {new Date().getFullYear()}. All rights reserved.
                                </p>
                            </div>

                            <div className="footer-column footer-menu">
                                <h4 className="footer-title footer-menu-title">About MClimate</h4>
                                <ul className="footer-menu-list">
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/about-us">About us</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/blogs/blog">Blog</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/investors">Investors</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/careers">Careers</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/terms">Terms and conditions</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/payment-terms">Payment terms</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/privacy-policy">Privacy policy</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/contacts">Contact us</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/media-kit">Media kits</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="footer-column footer-menu">
                                <h4 className="footer-title footer-menu-title">Support</h4>
                                <ul className="footer-menu-list">
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://seemelissa.zendesk.com/hc/en-us" target="_blank">Help desk (FAQ)</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://seemelissa.zendesk.com/hc/en-us/requests/new" target="_blank">Submit a ticket</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/developers">Developers</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="footer-column footer-menu">
                                <h4 className="footer-title footer-menu-title">Business</h4>
                                <ul className="footer-menu-list">
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/smartbuildings">Smart buildings</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/utilities">Utilities</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/mcore">MCore</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/distributors">Partnerships</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/lorawan-resources">LoRaWAN Resources</a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://mclimate.eu/pages/enterprise">MClimate Enterprise</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="footer-column footer-menu social-menu">
                                <h4 className="footer-title footer-menu-title">Social</h4>
                                <ul className="footer-menu-list">
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://www.facebook.com/MClimate" target="_blank">
                                            <img style={{ marginRight: "5px", width: "15px", height: "15px" }} src="//cdn.shopify.com/s/files/1/0043/8874/9379/t/5/assets/Facebook.svg?v=174175073062969142111660218326/" />
                                            Facebook
                                        </a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://twitter.com/MClimate_" target="_blank">
                                            <img style={{ marginRight: "5px", width: "15px", height: "15px" }} src="//cdn.shopify.com/s/files/1/0043/8874/9379/t/5/assets/Twitter.svg?v=60045844745023995211660218313/" />
                                            Twitter
                                        </a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://www.instagram.com/mclimate/" target="_blank">
                                            <img style={{ marginRight: "5px", width: "15px", height: "15px" }} src="//cdn.shopify.com/s/files/1/0043/8874/9379/t/5/assets/Instagram.svg?v=129512570591303475821660218332/" />
                                            Instagram
                                        </a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://www.linkedin.com/company/mclimate/" target="_blank">
                                            <img style={{ marginRight: "5px", width: "15px", height: "15px" }} src="//cdn.shopify.com/s/files/1/0043/8874/9379/t/5/assets/LinkedIn.svg?v=5013984139899447731660218343/" />
                                            LinkedIn
                                        </a>
                                    </li>
                                    <li className="footer-menu-list-item">
                                        <a className="footer-menu-list-item-anchor" href="https://www.youtube.com/channel/UCtRfSPGU0MLo_cRPGz_p2Rg" target="_blank">
                                            <img style={{ marginRight: "5px", width: "15px", height: "15px" }} src="//cdn.shopify.com/s/files/1/0043/8874/9379/t/5/assets/YouTube.svg?v=7976100472276691561660218319/" />
                                            YouTube
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="lorawan-partner footer-row-bottom">
                            <img style={{ width: "300px" }} src="//cdn.shopify.com/s/files/1/0043/8874/9379/t/5/assets/eit-logo.png?v=99016244673010216701658486836/" />
                            <div className="footer-column footer-content" style={{ padding: 0 }}>
                                <h4 className="footer-title footer-menu-title">
                                    We accept payment via
                                </h4>
                                <div className="footer-menu-text">
                                    <img src="https://cdn.shopify.com/s/files/1/0043/8874/9379/files/payment.png?v=1614342782" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}


export default Home